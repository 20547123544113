/* this file is transformed by vux-loader */
/* eslint-disable */
import { Loading } from 'vue-ydui/dist/lib.rem/dialog';
export default {
  data() {
    return {
      style: {
        width: '100%',
        height: this.height + 'rem'
      },
      map: {},
      iosGeoCoder: {}
    };
  },
  props: {
    //里面存放的也是数据，与data里面的数据不同的是，这里的数据是从其他地方得到的数据		　　　　　　
    height: {
      type: Number,
      default: 6
    }
  },
  mounted() {
    this.map = new BMap.Map("mapDiv");
    //var point = new BMap.Point(this.longitude, this.latitude);　　　//116.404, 39.915

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'http://api.map.baidu.com/api?v=2.0&ak=vxbA01b31wU4goMSUnYUmIuZV1wi1izk';
    document.body.appendChild(s);
  },
  methods: {
    pointTo(x, y) {
      this.map.clearOverlays();
      this.$dialog.loading.open('正在加载地图...');
      var p = new BMap.Point(x, y);
      var marker = new BMap.Marker(p); // 创建标注
      this.map.centerAndZoom(p, 18);
      this.map.addOverlay(marker);

      //设置覆盖物：圆
      var circleObj = new BMap.Circle(p, 100);
      circleObj.setStrokeOpacity(0.4);
      circleObj.setFillOpacity(0.3);
      circleObj.setStrokeWeight(1.1);
      circleObj.setFillColor("#ADD8E6");
      this.map.addOverlay(circleObj);
      this.$dialog.loading.close();
    },
    getGeocoder(x, y) {
      var point = new BMap.Point(x, y);
      var geoc = new BMap.Geocoder(); //逆地址解析类
      var self = this;
      geoc.getLocation(point, function (rs) {
        var addComp = rs.addressComponents;
        self.iosGeoCoder = addComp;
        self.$emit('onGetGeocoder', self.iosGeoCoder);
      });
    }
  }
};